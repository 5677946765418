import { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { HeaderBack } from '@/components/common/header';
import { PlacementBanner } from "../placement";
import { DealerSection } from "../dealer";
import { TagButton } from "../common/button";
import { VehicleModelVariant, VehicleReviewAuthor, VariantSeriesSale, PopularCarBrands, VehicleOwnershipCost } from ".";
import { ExpendMore, IconStar } from "../common/icon";
import { Progress, Skeleton } from "@mantine/core";
import { _numToPriceBetween, _getDealerList, _getAllVehicleTopList, _handleOnClickCompare, _handleOnClickCancelCompare, _getAllVehicleSeries, _handleOnClickGoCompare, _toDecimalWithoutRounding, _getMinMaxValueArrange, _getNextNewsList, _getAllAuthor, _getUserReview, _convertRatingAverage, _convertRatingAveragePercentage, _getAllVehicleModelList, _getPlacement, removeIsWebviewPath } from "../../libraries/common";
import { _postURL } from "../../pages/api";
import { getCookie, hasCookie } from "cookies-next";
import { PhoneCallListModal } from "../modal";
import { useRouter } from "next/router";
import { colorBlueActiveCode, mobileViewWidth, pageLink, placementArea } from "../../config/site";
import { CompareBottomButton } from "../compare";
import Link from "next/link";
import { LoginModal } from "../modal";
import { SkeletonParagraph } from "../common/skeleton";
import { useViewportSize } from "@mantine/hooks";
import Redux from "@/action";
import { Footer } from "../common/footer";
import { RelatedModel } from ".";
import { AllMeta } from '@/components/common/meta';
import Head from "next/head";
import { MyBody } from "../body/body";
import { CustomLink } from "../app/customLink";
import { customOpenModal } from "../../libraries/appCommon";

const vehicleModelImageList = [
    {id: 1, text: "specs", image: "/assets/images/document_icon.png", value: "spec"},
    {id: 2, text: "news", image: "/assets/images/news_icon.png", value: "news"},
    {id: 3, text: "videos", image: "/assets/images/video_icon.png", value: "video"},
    {id: 4, text: "calculator", image: "/assets/images/calculator_icon.png", value: "calculator"}
]

export const VehicleSpecBox = (props) => {
    const { label, input, image, inputStyle = {} } = props
    return (
        <div className="vehicleModel_specItem">
            <div className="vehicleModel_specItem_left">
                <img className="w-100" src={image} />
            </div>
            <div className="vehicleModel_specItem_right">
                <p className="fontSize_14 fontColor_lightGrey2">
                    {label}
                </p>
                <p className={`fontSize_14 fontWeight_medium`} style={inputStyle}>
                    {input}
                </p>
            </div>
        </div>
    )
}

const ReviewerNewsModel = (props) => {
    const { t, loading = false, newsList = [], fetchedData, router } = props
    if(loading == true){
        return (
            <div className="vehicleReviewNews containerPadding">
                <div className="vehicleReviewNews_titleRow">
                    <p className="fontSize_16 fontWeight_bold">
                        {`${fetchedData?.name} ${t('news')}`} 
                    </p>
                    <CustomLink href={removeIsWebviewPath(router.asPath) + "/news"}>
                        <div className="vehicleReviewNews_titleImage cursorPointer">
                            <img 
                                className="w-100"
                                src="/assets/images/right_circle_blue_icon.svg"
                            />
                        </div>
                    </CustomLink>
                </div>
                <div className="vehicleReviewNews_list">
                    {
                        _.times(3, (key)=>(
                            <div key={key} className="vehicleReviewNews_listItem">
                                <div className="vehicleReviewNews_listItem_imageOuter">
                                    <div className="vehicleReviewNews_listItem_image">
                                        <div className="absoluteSkeletonBox">
                                            <Skeleton height={'100%'} radius={'2rem'} />
                                        </div>
                                    </div>
                                </div>
                                <div className="vehicleReviewNews_listItem_content">
                                    <div className="fontSize_14 fontWeight_semiBold vehicleReviewNews_listItem_content_title">
                                        <SkeletonParagraph row={2} isTitle />
                                    </div>
                                    <div className="vehicleReviewNews_listItem_content_bottom">
                                        <div>

                                        </div>
                                        <div className="fontSize_12 fontColor_grey3">
                                            <SkeletonParagraph row={1} isDate />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        )
    }
    return (
        <>
            {
                !_.isEmpty(newsList) &&
                <div className="vehicleReviewNews containerPadding">
                    <div className="vehicleReviewNews_titleRow">
                        <p className="fontSize_16 fontWeight_bold">
                            {`${fetchedData?.name} ${t('news')}`} 
                        </p>
                        <CustomLink href={removeIsWebviewPath(router.asPath) + "/news"}>
                            <div className="vehicleReviewNews_titleImage cursorPointer">
                                <img 
                                    className="w-100"
                                    src="/assets/images/right_circle_blue_icon.svg"
                                />
                            </div>
                        </CustomLink>
                    </div>
                    <div className="vehicleReviewNews_list">
                        {
                            _.reduce(newsList, (result, value, key)=>{
                                result.push(
                                    <CustomLink key={key} href={pageLink.news.newsMidUrl + value.link}>
                                        <div className="vehicleReviewNews_listItem cursorPointer">
                                            <div className="vehicleReviewNews_listItem_imageOuter">
                                                <div className="vehicleReviewNews_listItem_image" style={{backgroundImage: `url(${process.env.NEXT_PUBLIC_FS_URL + value.cover_image})`}} />
                                            </div>
                                            <div className="vehicleReviewNews_listItem_content">
                                                <p className="fontSize_14 fontWeight_semiBold vehicleReviewNews_listItem_content_title">
                                                    {value.title}
                                                </p>
                                                <div className="vehicleReviewNews_listItem_content_bottom">
                                                    {
                                                        _.reduce(JSON.parse(value?.vehicle_brand_tag), (result2, value2, key2)=>{
                                                            if(!_.isEmpty(value2)){
                                                                result2.push(
                                                                    <TagButton key={`${key}-${key2}`} label={value2.name} linkUrl={pageLink.vehicle.vehicleMidUrl + value2.custom_url} />
                                                                )
                                                            }
                                                            return result2
                                                        },[])
                                                    }
                                                    <div className="vehicleReviewNews_listItem_content_bottom_date">
                                                        <p className='fontSize_12 vehicleReviewNews_listItem_content_bottom_date_text'>
                                                            {value.created_time}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </CustomLink>
                                )
                                return result
                            },[])
                        }
                    </div>
                </div>
            }
        </>
    )
}

const UserReviewSection = (props) => {
    const { fetchedData, userReviewList = [], t, router, loading = false, isLogin = {} } = props
    
    const [ratingDropdown, setRatingDropdown] = useState(false)

    // login 
    const [opened, setOpened] = useState(false)

    if(loading == true){
        return (
            <div className="vehicleUserReview containerPadding">
                <div className="vehicleUserReview_title">
                    <p className="fontSize_16 fontWeight_bold">
                        {`${fetchedData?.name} ${t('user_review')}`} 
                    </p>
                </div>
                <div className="vehicleUserReview_container">
                    <div className="vehicleUserReview_top">
                        <div className="vehicleUserReview_row1">
                            <p className="fontSize_16 fontWeight_medium fontColor_lightGrey3">
                                {t('ratings_reviews')}
                            </p>
                            <div className="fontSize_12 fontColor_grey2" style={{width: '10rem'}}>
                                <SkeletonParagraph row={1} />
                            </div>
                        </div>
                        <div className="vehicleUserReview_row2">
                            <div className="vehicleUserReview_row2_rating">
                                <div className="vehicleUserReview_row2_rating_num" style={{width: '5rem'}} >
                                    <SkeletonParagraph row={1} isTitle />
                                </div>
                                <div className="vehicleUserReview_row2_rating_star_iconList positionRelative" style={{width: '10rem', height: '1rem'}} >
                                    <div className="absoluteSkeletonBox">
                                        <Skeleton height={'100%'} radius={'5rem'} />
                                    </div>
                                </div>
                                <div onClick={()=>{setRatingDropdown(!ratingDropdown)}} className="vehicleUserReview_row2_rating_dropdown_icon">
                                    {/* TODOS icon color */}
                                    {
                                        ratingDropdown == false ?
                                        <img 
                                            src="/assets/images/expand_more.svg"
                                        />
                                        :
                                        <img 
                                            src="/assets/images/expand_up_icon.svg"
                                        />
                                    }
                                </div>
                            </div>
                            <div className="vehicleUserReview_row2_button positionRelative">
                                <div className="absoluteSkeletonBox">
                                    <Skeleton height={'100%'} radius={'5rem'} />
                                </div>
                            </div>
                        </div>
                        {
                            ratingDropdown == true &&
                            <div className="vehicleUserReview_row3">
                                <div className="vehicleUserReview_row3_left">
                                    {
                                        _.reduce([5,4,3,2,1], (result, value, key)=>{
                                            result.push(
                                                <div key={key} className="vehicleUserReview_row3_left_row">
                                                    <p className="fontSize_14 fontColor_grey2">
                                                        {value}
                                                    </p>
                                                    <SkeletonParagraph row={1} />
                                                </div>
                                            )
                                            return result
                                        },[])
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    <div className="vehicleUserReview_commandList">
                        {
                            _.times(3, (key)=>(
                                <div key={key} className="vehicleUserReview_commandItem">
                                    <div className="vehicleUserReview_commandItem_header">
                                        <div className="fontSize_12 fontWeight_semiBold" style={{width: '10rem'}}>
                                            <SkeletonParagraph row={1} isTitle />
                                        </div>
                                        <div className="fontSize_12 fontColor_grey2">
                                            <SkeletonParagraph row={1} isDate />
                                        </div>
                                    </div>
                                    <div className="vehicleUserReview_commandItem_ratingList positionRelative">
                                        <div className="absoluteSkeletonBox">
                                            <Skeleton height={'100%'} radius={'5rem'} />
                                        </div>
                                    </div>
                                    <div className="vehicleUserReview_commandItem_description">
                                        <SkeletonParagraph row={2} />
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        )
    }
    return (
        <>
            <div className="vehicleUserReview containerPadding">
                <div className="vehicleUserReview_title">
                    <p className="fontSize_16 fontWeight_bold">
                        {`${fetchedData?.name} ${t('user_review')}`} 
                    </p>
                </div>
                <div className="vehicleUserReview_container">
                    <div className="vehicleUserReview_top">
                        <div className="vehicleUserReview_row1">
                            <p className="fontSize_16 fontWeight_medium fontColor_lightGrey3">
                                {t('ratings_reviews')}
                            </p>
                            <p className="fontSize_12 fontColor_grey2">
                                {`${userReviewList?.total_rating ?? 0} ${t('ratings')}`}
                            </p>
                        </div>
                        <div className="vehicleUserReview_row2">
                            <div className="vehicleUserReview_row2_rating">
                                <div className="vehicleUserReview_row2_rating_num">
                                    <p className="fontSize_25 fontWeight_bold">
                                        {_convertRatingAverage(userReviewList?.total_rating ?? 0, userReviewList?.total_review ?? 0)}
                                    </p>
                                    <p className="fontSize_10 vehicleUserReview_row2_rating_num_text">
                                        / 5
                                    </p>
                                </div>
                                <div className="vehicleUserReview_row2_rating_star_iconList">
                                    {
                                        _.reduce([1,2,3,4,5],(result, value, key)=>{
                                            result.push(
                                                <div key={key} className="vehicleUserReview_row2_rating_star_icon">
                                                    <IconStar  color={_convertRatingAverage(userReviewList?.total_rating ?? 0, userReviewList?.total_review ?? 0) >= value ? "#FFC327" : undefined} />
                                                </div>
                                            )
                                            return result
                                        },[])
                                    }
                                </div>
                                <div onClick={()=>{setRatingDropdown(!ratingDropdown)}} className="vehicleUserReview_row2_rating_dropdown_icon cursorPointer">
                                    {/* TODOS icon color */}
                                    {
                                        ratingDropdown == false ?
                                        <img 
                                            src="/assets/images/expand_more.svg"
                                        />
                                        :
                                        <img 
                                            src="/assets/images/expand_up_icon.svg"
                                        />
                                    }
                                </div>
                            </div>
                            <div 
                                onClick={()=>{
                                    isLogin == true 
                                    ? customRouterLink(removeIsWebviewPath(router.asPath) + "/write-review") 
                                    : setOpened(true)
                                }} 
                                className="vehicleUserReview_row2_button cursorPointer"
                            >
                                <p className="fontSize_14 fontColor_white">
                                    {t('write_review')}
                                </p>
                            </div>
                        </div>
                        {
                            ratingDropdown == true &&
                            <div className="vehicleUserReview_row3">
                                <div className="vehicleUserReview_row3_left">
                                    {
                                        _.reduce([5,4,3,2,1], (result, value, key)=>{
                                            result.push(
                                                <div key={key} className="vehicleUserReview_row3_left_row">
                                                    <p className="fontSize_14 fontColor_grey2">
                                                        {value}
                                                    </p>
                                                    <div className="vehicleUserReview_row3_left_progress">
                                                        <Progress 
                                                            value={userReviewList?.total_review > 0 ? ((userReviewList?.group_rating?.[value]?.length / userReviewList?.total_review) * 100).toFixed(0) : 0}
                                                            color={"#FFC327"}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                            return result
                                        },[])
                                    }
                                </div>
                                <div className="vehicleUserReview_row3_right">
                                    <p className="fontSize_24 fontColor_grey2">
                                        {`${_convertRatingAveragePercentage(userReviewList?.total_rating ?? 0, userReviewList?.total_review ?? 0)}%`}
                                    </p>
                                    <p className="fontSize_12 fontColor_grey2">
                                        {t('recommended')}
                                    </p>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="vehicleUserReview_commandList">
                        {
                            _.reduce(userReviewList?.data, (result, value, key)=>{
                                if(key <= 2){
                                    result.push(
                                        <div key={key} className="vehicleUserReview_commandItem">
                                            <div className="vehicleUserReview_commandItem_header">
                                                <p className="fontSize_12 fontWeight_semiBold">
                                                    {value.full_name}
                                                </p>
                                                <p className="fontSize_12 fontColor_grey2">
                                                    {value.created_time}
                                                </p>
                                            </div>
                                            <div className="vehicleUserReview_commandItem_ratingList">
                                                {
                                                    _.reduce([1,2,3,4,5],(result2, value2, key2)=>{
                                                        result2.push(
                                                            <div key={`${key}-${key2}`} className="vehicleUserReview_row2_rating_star_icon">
                                                                <IconStar  color={value2 <= value.rating ? "#FFC327" : undefined} />
                                                            </div>
                                                        )
                                                        return result2
                                                    },[])
                                                }
                                            </div>
                                            <div className="vehicleUserReview_commandItem_description">
                                                <p className="fontSize_12">
                                                    {value.review}
                                                </p>
                                            </div>
                                        </div>
                                    )
                                }
                                return result
                            },[])
                        }
                    </div>
                    {
                        !_.isEmpty(userReviewList?.data) &&
                        <CustomLink href={removeIsWebviewPath(router.asPath) + "/review"}>
                            <div className="vehicleUserReview_viewMoreButtonMargin cursorPointer">
                                <div className="viewMore cursorPointer">
                                    <p className='fontSize_14 fontWeight_semiBold fontColor_blue'>
                                        {t('view_more')}
                                    </p>
                                    <div className="viewMore_icon">
                                        <ExpendMore color={colorBlueActiveCode} />
                                    </div>
                                </div>
                            </div>
                        </CustomLink>
                    }
                </div>
            </div>
            <LoginModal 
                opened={opened}
                setOpened={setOpened}
                t={t}
                isWriteReview={true}
            />
        </>
    )
}

export const VehicleModel = (props) =>{
    const { t, fetchedData } = props
    const { store, dispatch } = Redux();
    const [loading, setLoading] = useState(true)

    // vehicle on sale
    const [vehicleOnSaleList, setVehicleOnSaleList] = useState([])

    // dealer
    const [dealerList, setDealerList] = useState([])
    const [phoneCallOpen, setPhoneCallOpen] = useState(false)
    const [phoneNumberList, setPhoneNumberList] = useState([])

    // top List brand and model
    const [vehicleTopList, setVehicleTopList] = useState({brand: [], model: []})

    // compare store
    const [compareList, setCompareList] = useState(hasCookie('compare_list') ? JSON.parse(getCookie('compare_list')) : []) 

    // news list
    const [newsList, setNewsList] = useState([]);

    // author list
    const [authorList, setAuthorList] = useState([]);

    // user review list
    const [userReviewList, setUserReviewList] = useState([]);
    const [userReviewTotal, setUserReviewTotal] = useState(0);
    const { width } = useViewportSize()

    // Similar Vehicle model List
    const [similarVehicleModelList, setSimilarVehicleModelList] = useState([])

    const router = useRouter()
    const containerRef = useRef(null)
    // TODOS arrange code
    let userCookies;
    let isLogin;

    userCookies = getCookie("user") && JSON.parse(getCookie("user"));
    isLogin = !_.isEmpty(userCookies) ? true : false;

    // author
    useEffect(()=>{
        let isMounted = true; // Flag to track component mount status

        const fetchData = async()=>{
            try{
                setAuthorList([])
                setSimilarVehicleModelList([])

                await _getAllVehicleSeries(setVehicleOnSaleList, {vehicle_model_id: fetchedData?.vehicle_model_id})
                await _getDealerList({vehicleBrandId: fetchedData.vehicle_brand_id, limit: 6, offset: 0}, setDealerList);

                if(store?.path?.dataAllVehicleTopList == undefined){
                    await _getAllVehicleTopList(dispatch);
                }

                if(!_.isEmpty(JSON.parse(fetchedData?.reviewer_id)) && !_.isEmpty(fetchedData?.vehicle_model_id)){
                    await _getAllAuthor(setAuthorList, {author_id_list: _.join(JSON.parse(fetchedData?.reviewer_id),','), vehicle_model_id: fetchedData?.vehicle_model_id, limit: 6, offset: 0})
                }
                
                if(!_.isEmpty(JSON.parse(fetchedData?.similar_vehicle_model_id))){
                    await _getAllVehicleModelList(setSimilarVehicleModelList, {vehicle_model_id_list: _.join(JSON.parse(fetchedData?.similar_vehicle_model_id), ',')});
                }
                if(store?.path?.dataPlacement == undefined){
                    await _getPlacement(dispatch)
                }
                
                await _getUserReview(setUserReviewList, {brand_custom_url: fetchedData?.brand_custom_url,model_custom_url: fetchedData?.model_custom_url})
                await _getNextNewsList({offset: 0, limit: 3, vehicle_model_id: fetchedData?.vehicle_model_id, type: 1, willGoEmpty: true, noMergeData: true}, setNewsList)
            } catch(error) {
                console.log(error)
            } finally {
                if (isMounted) {
                    setLoading(false)
                }
            }
        }

        if(fetchedData){
            fetchData();
            if(window.innerWidth <= mobileViewWidth){
                window.scrollTo({
                    top: 1,
                    behavior: 'instant'
                });
            } else {
                if (containerRef.current) {
                    containerRef.current.scrollTo({
                    top: 0,
                    behavior: 'instant'
                    });
                }
            }
        }

        return () => {
            isMounted = false; // Mark the component as unmounted
        };
    },[fetchedData])  

    const _handleOnClickCategory = (value) => {
        if(value == "calculator"){
            return customRouterLink(pageLink.calculator.vehicleLoad)
        }
        return customRouterLink(removeIsWebviewPath(router.asPath) + "/" + value)
    }

    return (
        <>
            <AllMeta
                title={t('vehicleBrandModelPage_meta_title', { brandModel: fetchedData?.name })}
                description={t('vehicleBrandModelPage_meta_description', { brand: fetchedData?.brand_name,  brandModel: fetchedData?.name })}
                keyword={t('vehicleBrandModelPage_meta_keyword', { brandModel: fetchedData?.name})}
                image={!_.isEmpty(fetchedData?.cover_image_path) ? `${process.env.NEXT_PUBLIC_FS_URL}${fetchedData?.cover_image_path}` : undefined}
                imageWidth={1275}
                imageheight={666}
            />
            <Head><title>{t('vehicleBrandModelPage_title', {brandModel: fetchedData?.name})}</title></Head>
            <MyBody
                t={t}
                containerRef={containerRef}
                containerStyle={(compareList.length >= 1 && width <= mobileViewWidth) ? {paddingBottom: `6.4rem`} : {}}
                containerClass={"vehicleModelContainer"}
                headerComponent={
                    <HeaderBack t={t} />
                }
                showNavigation={true}
                navigationComponent={
                    compareList.length >= 1 ?
                    <CompareBottomButton compareList={compareList} t={t} handleOnClickGoCompare={(value)=>_handleOnClickGoCompare(value, router)} />
                    :
                    null
                }
                modalComponent={
                    <PhoneCallListModal
                        phoneCallOpen={phoneCallOpen}
                        setPhoneCallOpen={setPhoneCallOpen} 
                        phoneNumberList={phoneNumberList}
                        t={t}
                    />
                }
            >
                <VehicleModelVariant isSwitchAllow={false} t={t} imagePath={fetchedData?.cover_image_path} />
                    <div className="vehicleModel_Box">
                    <div className="containerPadding">
                        <p className="fontSize_20 fontWeight_bold vehicleModel_modelTitle">
                            {fetchedData?.name}
                        </p>
                        <p className="fontSize_16 fontWeight_semiBold fontColor_lightBlue">
                            {_numToPriceBetween(fetchedData?.min_price, fetchedData?.max_price)}
                        </p>
                        <div className="vehicleModel_specList">
                            <VehicleSpecBox image={"/assets/images/icon/vehicle_body_icon.png"} label={t("body_type")} input={`${fetchedData?.doors > 0 ? fetchedData?.doors + "-door" : ""} ${fetchedData?.body_type}`} />
                            {
                                fetchedData?.fuel == "EV" ?
                                <VehicleSpecBox image={"/assets/images/icon/ev_range.png"} label={t("ev_range")} input={`${_getMinMaxValueArrange(fetchedData?.min_ev_range, fetchedData?.max_ev_range)} km`} />
                                :
                                <VehicleSpecBox image={"/assets/images/icon/capacity_icon.png"} label={t("capacity")} input={`${_getMinMaxValueArrange(fetchedData?.min_capacity, fetchedData?.max_capacity, 1)} L`} />
                            }
                            {/* <VehicleSpecBox image={"/assets/images/icon/capacity_icon.png"} label={t("capacity")} input={`${_getMinMaxValueArrange(fetchedData?.min_capacity, fetchedData?.max_capacity, 1)} L`} /> */}
                            <VehicleSpecBox image={"/assets/images/icon/transmission_icon.png"} label={t('transmission')} input={fetchedData?.forward_ratio ?? "-"} />
                            {
                                fetchedData?.fuel == "EV" ?
                                <VehicleSpecBox image={"/assets/images/icon/horsePower_icon.png"} label={t('horsepower')} input={`${_getMinMaxValueArrange(fetchedData?.min_electric_horsepower, fetchedData?.max_electric_horsepower)} PS`} />
                                :
                                <VehicleSpecBox image={"/assets/images/icon/horsePower_icon.png"} label={t('horsepower')} input={`${_getMinMaxValueArrange(fetchedData?.min_horsepower, fetchedData?.max_horsepower)} PS`} />
                            }
                            {/* <VehicleSpecBox image={"/assets/images/icon/horsePower_icon.png"} label={t('horsepower')} input={`${_getMinMaxValueArrange(fetchedData?.min_horsepower, fetchedData?.max_horsepower)} PS`} /> */}
                        </div>
                    </div>
                    <div className="containerPadding vehicleModel_categoryOuter">
                        <div className="vehicleModel_category">
                            {
                                _.reduce(vehicleModelImageList, (result, value, key)=>{
                                    result.push(
                                        <div onClick={()=>{_handleOnClickCategory(value.value)}} key={key} className="vehicleModel_categoryItem cursorPointer">
                                            <div className={`vehicleModel_categoryImageOuter`}>
                                                <img 
                                                    className="w-100"
                                                    src={value.image}
                                                />
                                            </div>
                                            <p className="fontSize_14 fontWeight_medium">
                                                {t(value.text)}
                                            </p>
                                        </div>
                                    )
                                    return result
                                },[])
                            }
                        </div>
                    </div>
                    <VariantSeriesSale 
                        t={t} data={vehicleOnSaleList} title={fetchedData?.name}
                        handleOnClickCompare={(value, type)=>_handleOnClickCompare(value, type, setCompareList)} 
                        isActiveVaraintList={_.compact(_.map(compareList, (value) => value.variant_id))} 
                        loading={loading}
                    />
                    <div className="vehicleModel_placementBannerMargin">
                    </div>
                    <VehicleOwnershipCost t={t} showCompare={false} data={fetchedData} title={fetchedData?.name} />
                    <div className="mt_12">
                        <PlacementBanner
                            // can't pass object because inside using memo
                            loading={store?.path?.dataPlacement == undefined ? true : false}
                            area={placementArea?.vehicleMdeolDetailPage_afterOwnerShip} 
                            placement_id={store?.path?.dataPlacement?.[placementArea?.vehicleMdeolDetailPage_afterOwnerShip]?.placement_id}
                            type={store?.path?.dataPlacement?.[placementArea?.vehicleMdeolDetailPage_afterOwnerShip]?.type}
                            image_path={store?.path?.dataPlacement?.[placementArea?.vehicleMdeolDetailPage_afterOwnerShip]?.image_path}
                            url={store?.path?.dataPlacement?.[placementArea?.vehicleMdeolDetailPage_afterOwnerShip]?.url}
                            script={store?.path?.dataPlacement?.[placementArea?.vehicleMdeolDetailPage_afterOwnerShip]?.script}
                        />
                    </div>
                    <VehicleReviewAuthor title={fetchedData?.name} data={authorList} t={t} loading={loading} />
                    <ReviewerNewsModel t={t} newsList={newsList} loading={loading} fetchedData={fetchedData} router={router} />
                    <UserReviewSection 
                        fetchedData={fetchedData} 
                        userReviewList={userReviewList} 
                        t={t}
                        router={router}
                        loading={loading}
                        isLogin={isLogin}
                    />
                    <RelatedModel
                        vehicleModelList={similarVehicleModelList}
                        t={t}
                        loading={loading}
                        isSimilar={true}
                    />
                    <PlacementBanner
                        // can't pass object because inside using memo
                        loading={store?.path?.dataPlacement == undefined ? true : false}
                        area={placementArea?.vehicleModelDetailPage_beforeDealer} 
                        placement_id={store?.path?.dataPlacement?.[placementArea?.vehicleModelDetailPage_beforeDealer]?.placement_id}
                        type={store?.path?.dataPlacement?.[placementArea?.vehicleModelDetailPage_beforeDealer]?.type}
                        image_path={store?.path?.dataPlacement?.[placementArea?.vehicleModelDetailPage_beforeDealer]?.image_path}
                        url={store?.path?.dataPlacement?.[placementArea?.vehicleModelDetailPage_beforeDealer]?.url}
                        script={store?.path?.dataPlacement?.[placementArea?.vehicleModelDetailPage_beforeDealer]?.script}
                    />
                    <div className="vehicleModel_dealerMargin">
                        <DealerSection 
                            t={t} 
                            data={dealerList} 
                            brandName={fetchedData?.brand_name} 
                            brandCustomUrl={fetchedData?.brand_custom_url}
                            loading={loading} 
                            setPhoneNumberList={setPhoneNumberList}
                            setPhoneCallOpen={setPhoneCallOpen}
                        />
                    </div>
                    <div className="vehicleBrandsPopularCarMargin">
                        <PopularCarBrands t={t} data={store?.path?.dataAllVehicleTopList?.brand} loading={store?.path?.dataAllVehicleTopList == undefined ? true : false} />
                    </div>
                </div>
            </MyBody>
        </>
    )
}